export default {
  menuWeekPanelMessages: {
    noDailyMenuAvailable: 'Немає меню... це вихідний, або піст, або ще не створили, або йди шукай МОВ',
    noWeekMenuAvailable: 'Для цих дат ще немає меню',
    nothingSelected: 'Нічого не обрано',
    purchasedMenu: 'Меню придбано, тому ваше замовлення не може бути змінено.'
  },
  menuWeekPanelLabels: {
    without: 'Без',
    dishAutosuggestion: 'Додати страву',
  },
  menuWeekPanelButtons: {
    expandAll: 'Розгорнути все',
    collapseAll: 'Згорнути все'
  },
  menuSidebarToolbar: {
    youAreGoingToUnpublishPeriodMenu: 'Меню вже опубліковано. При редагувані всі зробленні замовленння будуть втрачені навіки.',
    youAreGoingToPublishPeriodMenu: 'Ви збираєтесь опублікувати меню',
    youAreGoingToCreatePeriodMenu: 'Ви збираєтесь створити меню на тиждень',
    youAreGoingToCreatePeriodMenuTillWeekend: 'Ви збираєтесь створити меню до кінця тижня',
    youAreGoingToCreateOneDayPeriodMenu: 'Ви збираєтесь створити меню на один день',
    youAreGoingToCreateDailyMenu: 'Ви збираєтесь включити цей день у попереднє меню. В разі успішного додавання, він стане крайнім днем: всі попередні дні будуть автоматично включені до меню',
    youAreGoingToCreateFirstDayOfDailyMenu: 'Ви збираєтесь включити цей день у наступне меню. В разі успішного додавання, він стане першим днем: всі подальші дні будуть автоматично включені до меню',
    youAreGoingToDeletePeriodMenu: 'Ви збираєтесь видалити меню, відмінити цю дію неможливо',
    youAreGoingToDeleteDailyMenu: 'Ви збираєтесь видалити день з меню, відмінити цю дію неможливо. Попередній день буде використаний в якості закриваючого дня меню. Усі дні, що входили до меню після дня на видалення, будуть додані до нового меню',
    youAreGoingToDeleteFirstDayOfDailyMenu: 'Ви збираєтесь видалити перший день з меню, відмінити цю дію неможливо. Наступний день буде використаний в якості першого дня меню',
    youAreGoingToPurchasePeriodMenu: 'Ви збираєтесь придбати меню, відмінити цю дію неможливо',
    shouldThisMenuBeUnpublished: 'Дійсно відмінити публікацію меню?',
    shouldThisMenuBePublished: 'Дійсно опублікувати меню?',
    shouldPeriodMenuBeCreated: 'Дійсно створити меню на тиждень?',
    shouldOneDayMenuBeCreated: 'Дійсно створити меню на один день?',
    shouldPeriodMenuTillWeekendBeCreated: 'Дійсно створити меню до кінця тижня?',
    shouldDailyMenuBeCreated: 'Дійсно додати цей день до попереднього меню?',
    shouldFirstDayDailyMenuBeCreated: 'Дійсно додати цей день до наступного меню?',
    shouldThisMenuBeDeleted: 'Дійсно видалити меню?',
    shouldThisDayBeDeletedFromMenu: 'Дійсно видалити цей день з меню?',
    shouldThisMenuBePurchased: 'Дійсно придбати меню?'
  },
  menuSidebarLabels: {
    createPeriodMenu: 'Створити меню',
    createPeriodMenuToWeekEnd: 'Створити меню до кінця тижня',
    createPeriodMenuForDay: 'Створити меню на день',
    createDailyMenu: 'Додати день в попереднє меню',
    createFirstDayDailyMenu: 'Додати день в наступне меню',
    publish: 'Опублікувати',
    unpublish: 'Редагувати',
    deleteMenu: 'Видалити меню',
    deleteDay: 'Видалити день',
    purchase: 'Придбати',
    openMenuPanel: 'Відкрити панель меню',
    closeMenuPanel: 'Закрити панель меню',
    status: 'Статус меню:'
  },
  menuSidebarStatuses: {
    DRAFT: 'Чорнове',
    PUBLISHED: 'Опубліковано',
    PURCHASED: 'Придбано',
    HOLIDAY: 'Вихідний',
  }
};
