export default {
  menuWeekPanelMessages: {
    noDailyMenuAvailable: 'No menu... it’s either holiday or hungryday or is not created yet',
    noWeekMenuAvailable: 'There is no menu for this dates at the moment',
    nothingSelected: 'Nothing selected',
    purchasedMenu: 'Menu is already PURCHASED, thus, your order can\'t be changed.'
  },
  menuWeekPanelLabels: {
    without: 'Without',
    dishAutosuggestion: 'Add Dish',
  },
  menuWeekPanelButtons: {
    expandAll: 'Expand All',
    collapseAll: 'Collapse All'
  },
  menuSidebarToolbar: {
    youAreGoingToUnpublishPeriodMenu: 'You are going to unpublish period menu',
    youAreGoingToPublishPeriodMenu: 'You are going to publish period menu',
    youAreGoingToCreatePeriodMenu: 'You are going to create period menu',
    youAreGoingToCreatePeriodMenuTillWeekend: 'You are going to create period menu till the end of week',
    youAreGoingToCreateOneDayPeriodMenu: 'You are going to create menu for one day',
    youAreGoingToCreateDailyMenu: 'You are going to add this day to the previous menu, it will become closing day for menu: all previous days will be added to this menu automatically',
    youAreGoingToCreateFirstDayOfDailyMenu: 'You are going to add this day to the next menu, it will become first day for menu: all days in between will be added to this menu automatically',
    youAreGoingToDeletePeriodMenu: 'You are going to delete the menu. This will also delete all related orders and cannot be undone',
    youAreGoingToDeleteDailyMenu: 'You are going to delete the day from the menu. This will also delete all related orders for the current day and cannot be undone. The menu will shorten to the previous day, and all the following days after the deleted day will be added to a new separate menu',
    youAreGoingToDeleteFirstDayOfDailyMenu: 'You are going to delete the first day from the menu. This will also delete all related orders and cannot be undone. Next day will be used as the first day of menu',
    youAreGoingToPurchasePeriodMenu: 'You are going to purchase period menu, this action cannot be undone',
    shouldThisMenuBeUnpublished: 'Should this menu be unpublished?',
    shouldThisMenuBePublished: 'Should this menu be published?',
    shouldPeriodMenuBeCreated: 'Should this menu be created?',
    shouldOneDayMenuBeCreated: 'Should this one day menu be created?',
    shouldPeriodMenuTillWeekendBeCreated: 'Should this menu be created till the end of week?',
    shouldDailyMenuBeCreated: 'Should this day be added to the previous menu?',
    shouldFirstDayDailyMenuBeCreated: 'Should this day be added to the next menu?',
    shouldThisMenuBeDeleted: 'Should this menu be deleted?',
    shouldThisDayBeDeletedFromMenu: 'Should this day be deleted from menu?',
    shouldThisMenuBePurchased: 'Should this menu be purchased?'
  },
  menuSidebarLabels: {
    createPeriodMenu: 'Create menu',
    createPeriodMenuToWeekEnd: 'Create menu till weekend',
    createPeriodMenuForDay: 'Create daily menu',
    createDailyMenu: 'Add day to the previous menu',
    createFirstDayDailyMenu: 'Add day to the next menu',
    publish: 'Publish',
    unpublish: 'Unpublish',
    deleteMenu: 'Delete menu',
    deleteDay: 'Delete day',
    purchase: 'Purchase',
    openMenuPanel: 'Open menu panel',
    closeMenuPanel: 'Close menu panel',
    status: 'Menu status:'
  },
  menuSidebarStatuses: {
    DRAFT: 'Draft',
    PUBLISHED: 'Published',
    PURCHASED: 'Purchased',
    HOLIDAY: 'Holiday',
  }
};
